<template>
    <f7-page name="updateCart">
        <!-- Navbar -->
        <f7-navbar title="Produkt ändern" back-link=""> </f7-navbar>
        <!-- Product detail -->
        <f7-block-title medium>{{ decodeURI(this.item.name) }}</f7-block-title>
        <f7-block strong>
            <f7-row>
                <f7-col width="33" @click="$refs.standalone.open()">
                    <div
                        v-if="!$f7.online"
                        class="skeleton-block"
                        style="width: 100px; height: 100px"
                    ></div>
                    <img v-if="$f7.online" :src="this.item.img" />
                </f7-col>
                <f7-col width="66">
                    <h4>Artikelnummer:</h4>
                    <p class="grey">{{ decodeURI(this.id) }}</p>
                    <h4>Barcode:</h4>
                    <p class="grey">{{ decodeURI(this.item.barcode) }}</p>
                    <h4>Preis:</h4>
                    <p class="grey">{{ decodeURI(this.item.price) }}</p>
                    <div v-if="this.item.category == 'Zubehör'">
                        <h4>Beschreibung:</h4>
                        <p class="grey">{{ this.item.name }}</p>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>
        <f7-block-title>Bestellen:</f7-block-title>
        <f7-list no-hairlines-md>
            <!-- <f7-list-input
                input-id="qtyUpdate"
                label="Anzahl"
                placeholder="0"
                clear-button
                @input:clear="unsetValue"
            ></f7-list-input> -->
            <f7-list-input
                :value="qtyNew"
                @input="qtyNew = $event.target.value"
                type="number"
                label="Anzahl"
                placeholder="0"
                clear-button
            ></f7-list-input>
            <f7-list-item
                checkbox
                v-if="this.item.category !== 'Zubehör'"
                :checked="sampleQtyCheckbox"
                @change="sampleQtyInput"
                title="davon eine Vorzeigekarte senden"
            ></f7-list-item>
        </f7-list>
        <f7-button fill large @click="updateCart_check1">Ändern</f7-button>
        <f7-button fill large @click="deleteFromCart()">Löschen</f7-button>
        <f7-button fill large @click="$f7router.back()" class="button-grey"
            >Abbrechen</f7-button
        >

        <!-- Show enlarged product photo -->
        <f7-photo-browser
            :photos="productImage"
            ref="standalone"
            :routable-modals="false"
            :toolbar="false"
        ></f7-photo-browser>
    </f7-page>
</template>

<script>
// Globals
let qtyKeypad = "";

// Vue
export default {
    data() {
        return {
            item: "",
            productsFiltered: [],
            searchString: "",
            params: { qty: 0, sampleQty: 0 },
            productImage: [],
            qtyNew: null,
            sampleQtyCheckbox: false,
        };
    },
    props: ["id", "qty", "sampleQty", "barcode", "index"],

    methods: {
        updateCart_check1: function() {
            console.log("performing addToCart_check1");

            //Add quantity to model
            // this.params.qty = parseInt(qtyKeypad.getValue());

            if (this.sampleQtyCheckbox === true) {
                this.params.sampleQty = 1;
            } else {
                this.params.sampleQty = 0;
            }

            if (isNaN(this.params.qty)) {
                this.params.qty = 0;
            }
            if (isNaN(this.params.sampleQty)) {
                this.params.sampleQty = 0;
            }

            // Quantity Warning
            if (this.params.qty >= 30 || this.params.sampleQty >= 30) {
                let _this = this;

                this.$f7.dialog
                    .create({
                        title: "Hinweis",
                        text:
                            "Bist du sicher, dass du so viele Artikel bestellen willst? Bitte anpassen, falls dies nicht Absicht war!",
                        buttons: [
                            {
                                text: "Bestellen",
                            },
                            {
                                text: "Anpassen",
                            },
                        ],
                        onClick: function(dialog, index) {
                            if (index === 0) {
                                _this.updateCart();
                            } else {
                                return;
                            }
                        },
                    })
                    .open();
            } else {
                this.updateCart();
            }
        },
        updateCart: function() {
            // Perform checks and exit with message if necessary
            console.log("performing checks");
            if (isNaN(this.qtyNew) === true || this.qtyNew < 1) {
                this.showMessage("Bitte eine Anzahl eingeben!", "error");
                return;
            }

            // Get current cart
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                cart = [];
            }

            // Prepare item
            let item = {
                name: this.item.name,
                id: this.item.id,
                bardoce: this.item.barcode,
                imageUrl: this.item.img,
                qty: this.qtyNew,
                sampleQty: this.sampleQtyCheckbox,
                price: this.item.price,
            };

            // Add sampleQty
            if (item.sampleQty === true) {
                item.sampleQty = 1;
            } else {
                item.sampleQty = 0;
            }

            // Set sampleQty to 0 if empty
            if (
                (this.params.sampleQty === "") |
                (this.params.sampleQty === null)
            ) {
                this.params.sampleQty = 0;
            }

            // Change item
            for (var i in cart) {
                if (cart[i].id === this.id) {
                    cart[i] = item;
                    break; //Stop this loop, we found it!
                }
            }
            localStorage.setItem("cart", JSON.stringify(cart));

            // Refresh cart & back to home screen
            this.$root.$emit("refreshCart");
            this.$root.$emit("refreshOrderOverview");
            this.$f7router.back();
        },
        qtyWarning: function(qty) {
            console.log("executing qty warning");

            if (qty >= 30) {
                this.$f7.dialog.alert(
                    "Bist du sicher, dass du so viele Artikel bestellen willst? Bitte anpassen, falls dies nicht Absicht war!",
                    "Hinweis"
                );
            }
        },
        removeByAttr: function(arr, attr, value) {
            var i = arr.length;
            while (i--) {
                if (
                    arr[i] &&
                    arr[i].hasOwnProperty(attr) &&
                    arguments.length > 2 &&
                    arr[i][attr] === value
                ) {
                    arr.splice(i, 1);
                }
            }
            return arr;
        },
        deleteFromCart: function(index) {
            // Get current cart
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                cart = [];
            }
            // Prepare item
            console.log("deleting: " + this.id);

            // // Delete item & refresh cart
            // cart.splice(index, 1);
            this.removeByAttr(cart, "id", this.id);
            localStorage.setItem("cart", JSON.stringify(cart));
            this.$root.$emit("refreshCart");
            this.$f7router.navigate("/", {
                transition: "f7-dive",
                reloadAll: true,
            });
        },
        unsetValue: function(field) {
            qtyKeypad.destroy();
            qtyKeypad = this.$f7.keypad.create({
                inputEl: "#qtyUpdate",
                valueMaxLength: 3,
                dotButton: false,
                toolbarCloseText: "Fertig",
            });
            this.params.qty = 0;
            this.params.sampleQty = 0;
        },

        sampleQtyInput: function(event) {
            console.log("Set sample Quantity 'true' or 'false'");
            if (event.target.checked) {
                this.sampleQtyCheckbox = true;
            } else {
                this.sampleQtyCheckbox = false;
            }
        },
    },
    beforeMount() {
        let _this = this;

        // Load product
        this.item = this.$root.$data.products.find(
            (product) => product.id === _this.id
        );

        // Load image
        let cacheSmall = "f073062f50e48eb0f0998593e568d857";
        let cacheLarge = "c687aa7517cf01e65c009f6943c2b1e9";
        let imgUrl = this.item.img;
        imgUrl = imgUrl.replace(cacheSmall, cacheLarge);
        this.productImage.push(imgUrl);
    },
    mounted() {
        this.qtyNew = this.qty;

        if (parseInt(this.sampleQty) > 0) {
            this.sampleQtyCheckbox = true;
            this.paramsSampleQty = 1;
        } else {
            this.sampleQtyChebkox = false;
            this.paramsSampleQty = 0;
        }

        // Initialize keypads
        console.log("initialiting keypad");
        qtyKeypad = this.$f7.keypad.create({
            inputEl: "#qtyUpdate",
            valueMaxLength: 3,
            dotButton: false,
            toolbarCloseText: "Fertig",
            value: this.qty,
        });

        // Check if large image version of product exists
        if (this.$f7.online) {
            fetch(this.$root.$data.endpoint + "/api/check_magento.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body:
                    '{"productImg":"' +
                    this.productImage[0] +
                    '", "productUrl":"' +
                    this.item.urlDetail +
                    '"}',
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.result === "imageExists") {
                        // console.log(data.result);
                    } else {
                        // console.log(data.result);
                    }
                });
        }
    },
};
</script>

<style scoped>
img {
    display: block;
    max-width: 100%;
}
.grey {
    color: grey;
    margin-top: 0px;
    margin-bottom: 0px;
}
h4 {
    margin-top: 10px;
    margin-bottom: 0px;
}
h3 {
    margin-top: 0px;
}
.button {
    margin: 10px 10px 10px 10px;
}
.button-grey {
    background-color: grey;
    margin-bottom: 30px;
}

.icon-back {
    display: none;
}
</style>

<style>
.icon-back {
    display: none;
}
</style>
