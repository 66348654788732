<template>
    <f7-page name="addCart">
        <!-- Navbar -->
        <f7-navbar title="Produkt hinzufügen" back-link=""> </f7-navbar>
        <!-- Product detail -->
        <f7-block-title medium>{{ decode(this.item.name) }}</f7-block-title>
        <f7-block strong>
            <f7-row>
                <f7-col width="33" @click="$refs.standalone.open()">
                    <div
                        v-if="!$f7.online"
                        class="skeleton-block"
                        style="width: 100px; height: 100px"
                    ></div>
                    <img v-if="$f7.online" :src="this.item.img" />
                </f7-col>
                <f7-col width="66">
                    <h4>Artikelnummer:</h4>
                    <p class="grey">{{ decodeURI(this.id) }}</p>
                    <h4>Barcode:</h4>
                    <p class="grey">{{ this.item.barcode }}</p>
                    <h4>Preis:</h4>
                    <p class="grey">{{ this.item.price }}</p>
                    <h4>Beschreibung:</h4>
                    <p class="grey">{{ this.item.name }}</p>
                </f7-col>
            </f7-row>
        </f7-block>
        <f7-block-title>Bestellen:</f7-block-title>
        <f7-list no-hairlines-md>
            <!-- <f7-list-input
                input-id="qty"
                label="Anzahl"
                placeholder="0"
                clear-button
                @input:clear="unsetValue('qty')"
            ></f7-list-input>         -->
            <f7-list-input
                :value="qty"
                @input="qty = $event.target.value"
                placeholder="0"
                label="Anzahl"
                type="number"
                clear-button
            ></f7-list-input>
            <f7-list-item
                checkbox
                v-if="this.item.category !== 'Zubehör'"
                @change="sampleQtyInput"
                :checked="sampleQty"
                title="davon eine Vorzeigekarte senden"
            ></f7-list-item>
        </f7-list>
        <f7-button fill large @click="addToCart_check1">Hinzufügen</f7-button>
        <f7-button fill large @click="$f7router.back()" class="button-grey"
            >Abbrechen</f7-button
        >

        <!-- Show enlarged product photo -->
        <f7-photo-browser
            :photos="productImage"
            ref="standalone"
            :routable-modals="false"
            :toolbar="false"
        ></f7-photo-browser>
    </f7-page>
</template>

<script>
import popover from "framework7-vue/components/popover";
import AutcompleteComponent from "framework7/components/autocomplete/autocomplete";

const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

// Globals
// let qtyKeypad = "";

// Vue
export default {
    data() {
        return {
            item: "",
            productsFiltered: [],
            searchString: "",
            qty: null,
            sampleQty: false,
            actionGridOpened: false,
            productImage: [],
        };
    },
    props: ["id"],

    methods: {
        addToCart_check1: function() {
            console.log("performing addToCart_check1");

            //Add quantity to model
            // this.qty = parseInt(qtyKeypad.getValue());

            if (isNaN(this.qty)) {
                this.qty = 0;
            }

            if (this.sampleQty === true) {
                this.sampleQty === 1;
            } else {
                this.sampleQty === 0;
            }

            // Quantity Warning
            if (this.qty >= 30) {
                let _this = this;

                this.$f7.dialog
                    .create({
                        title: "Hinweis",
                        text:
                            "Bist du sicher, dass du so viele Artikel bestellen willst? Bitte anpassen, falls dies nicht Absicht war!",
                        buttons: [
                            {
                                text: "Bestellen",
                            },
                            {
                                text: "Anpassen",
                            },
                        ],
                        onClick: function(dialog, index) {
                            if (index === 0) {
                                _this.addToCart();
                            } else {
                                return;
                            }
                        },
                    })
                    .open();
            } else {
                this.addToCart();
            }
        },

        sampleQtyInput: function(event) {
            console.log("Set sample Quantity 'true' or 'false'");
            if (event.target.checked) {
                this.sampleQty = true;
            } else {
                this.sampleQty = false;
            }
        },

        addToCart: function() {
            let _this = this;

            // Perform checks and exit with message if necessary
            console.log("performing checks");
            if (this.qty === null || this.qty === "" || this.qty === 0) {
                _this.showMessage("Bitte eine Anzahl eingeben!", "error");
                return;
            }

            // Get current cart
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                cart = [];
            }
            // Prepare item
            let item = {
                name: this.item.name,
                id: this.item.id,
                barcode: this.item.barcode,
                imageUrl: this.item.img,
                qty: parseInt(this.qty),
                sampleQty: this.sampleQty,
                price: this.item.price,
            };

            // Add sampleQty
            if (item.sampleQty === true) {
                item.sampleQty = 1;
            } else {
                item.sampleQty = 0;
            }

            // Check if item already exists
            let itemExists = "";
            cart.forEach(function(itemInCart) {
                if (itemInCart.id === item.id) {
                    _this.showMessage(
                        "Produkt ist bereits in Bestellung aufgenommen!",
                        "error"
                    );
                    itemExists = "X";
                }
            });
            if (itemExists === "X") {
                return;
            }

            // Add item
            cart.unshift(item);
            localStorage.setItem("cart", JSON.stringify(cart));
            this.$root.$emit("refreshCart");
            this.$f7router.back();
        },
        // unsetValue: function(field) {
        //     if (field === "qty") {
        //         qtyKeypad.destroy();
        //         qtyKeypad = this.$f7.keypad.create({
        //             inputEl: "#qty",
        //             valueMaxLength: 3,
        //             dotButton: false,
        //             toolbarCloseText: "Fertig",
        //         });
        //         this.qty = 0;
        //     }
        // },
        decode(string) {
            return entities.decode(string);
        },

        showImage() {
            console.log("show image...");
        },
    },
    beforeMount() {
        // Load product
        let _this = this;
        this.item = this.$root.$data.products.find(
            (product) => product.id === _this.id
        );

        // Load image
        let cacheSmall = "f073062f50e48eb0f0998593e568d857";
        let cacheLarge = "c687aa7517cf01e65c009f6943c2b1e9";
        let imgUrl = this.item.img;
        imgUrl = imgUrl.replace(cacheSmall, cacheLarge);
        console.log(imgUrl);
        this.productImage.push(imgUrl);
    },
    mounted() {
        // Initialize keypad
        // qtyKeypad = this.$f7.keypad.create({
        //     inputEl: "#qty",
        //     valueMaxLength: 3,
        //     dotButton: false,
        //     toolbarCloseText: "Fertig",
        //     on: {
        //         change: function() {
        //             console.log('keypad changed');
        //         },
        //         open: function(keypad, value) {
        //             console.log(keypad, value);
        //         }
        //     }
        // });

        // Show message if product out of stock
        if (this.item.outofstock == true) {
            let _this = this;
            this.$f7.dialog.alert(
                "Der Artikel ist zur Zeit nicht lieferbar!",
                "Achtung",
                function() {
                    _this.$f7router.back();
                }
            );
        }

        // Check if large image version of product exists
        if (this.$f7.online) {
            fetch(this.$root.$data.endpoint + "/api/check_magento.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body:
                    '{"productImg":"' +
                    this.productImage[0] +
                    '", "productUrl":"' +
                    this.item.urlDetail +
                    '"}',
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.result === "imageExists") {
                        // console.log(data.result);
                    } else {
                        // console.log(data.result);
                    }
                });
        }
    },
};
</script>

<style scoped>
img {
    display: block;
    max-width: 100%;
}
.grey {
    color: grey;
    margin-top: 0px;
    margin-bottom: 0px;
}
h4 {
    margin-top: 10px;
    margin-bottom: 0px;
}
h3 {
    margin-top: 0px;
}
.button {
    margin: 10px 10px 0px 10px;
}
.button-grey {
    background-color: grey;
}
</style>

<style>
/* .keypad-button-letters {
    display: none;
} */
</style>
